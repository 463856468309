<template>
  <v-row no-gutters justify="center" align="center" class="mt-4">
    <v-card width="100%"
      ><v-container>
        <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy"
          ><h2 class="headtitle">สร้างคำถาม</h2>
          <v-row no-gutters justify="center" align="center" class="mt-5">
            <!-- <v-row> -->
            <v-col cols="12"
              >ชื่อแบบสอบถาม<v-col cols="6"
                ><v-text-field
                  :rules="rules.required"
                  outlined
                  dense
                  v-model="formName"
                  placeholder="ชื่อแบบสอบถาม"
                ></v-text-field></v-col
            ></v-col>
            <v-col cols="12"
              >รายละเอียดเพิ่มเติม<v-col cols="6"
                ><v-text-field
                  :rules="rules.required"
                  outlined
                  dense
                  v-model="description"
                  placeholder="รายละเอียดเพิ่มเติม"
                ></v-text-field></v-col
            ></v-col>
            <!-- <v-col cols="12"
              ><v-switch v-model="switch1" label="ใช้งาน"></v-switch
            ></v-col> -->
            <v-col class="mt-4"
              ><v-btn @click="cancel()">ยกเลิก</v-btn>
              <v-btn
                class="ml-12"
                color="#833133"
                style="color: white"
                @click="update()"
                >บันทึก</v-btn
              ></v-col
            >
          </v-row></v-form
        ></v-container
      >
    </v-card>
  </v-row>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      checkValidate: true,
      lazy: false,
      switch1: true,
      formId: "",
      formName: "",
      description: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"],
        password: [(v) => !!v || "Password is required"],
        image: [(v) => !!v || "Image is required"],
        name: [(v) => !!v || "Name is required"],
        sername: [(v) => !!v || "Sername is required"],
        duty: [(v) => !!v || "Duty is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "กรุณากรอกข้อมูล"],
      },
    };
  },
  created() {
    var response = JSON.parse(Decode.decode(localStorage.getItem("Questions")));
    console.log(response);
    (this.formId = response.id),
      (this.formName = response.name),
      (this.description = response.description);
  },
  methods: {
    async update() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          id: this.formId,
          name: this.formName,
          description: this.description,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/form/updateForm/` ,data
        );
        console.log(response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            title: `สร้างฟอร์มสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageSurvey");
        } else {
          this.$swal.fire({
            icon: "error",
            title: `สร้างฟอร์มไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      (this.formName = ""), (this.description = ""), this.resetValidation();
      this.$router.push("ManageSurvey");
    },
    resetValidation() {
      this.$refs.createForm.resetValidation();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>